import { Fragment } from "react"
import { Transition } from "@headlessui/react"
import Link from "next/link"
import { useState } from "react"
import SanityImage from "../../common/components/SanityImage"

const BlogSection = ({ content, posts }) => {
  const [max, setMax] = useState(3)

  const showMore = () => {
    if (typeof window !== "undefined" && window.innerWidth < 769) {
      setMax(max + 1)
    } else {
      setMax(max + 3)
    }
  }

  return (
    <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            {content.headline}
          </h2>
          <p className="mt-3 max-w-lg mx-auto text-base text-gray-500 sm:mt-4">{content.body}</p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 md:grid-cols-3 md:max-w-none">
          {posts.length > 0 ? (
            posts.map((post, i) => (
              <Transition
                as={Fragment}
                key={i}
                show={i < max}
                enter="transform transition duration-[350ms]"
                enterFrom="opacity-0 scale-50"
                enterTo="opacity-100 scale-100">
                <article className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  {post.mainImage ? (
                    <div className="flex-shrink-0 min-h-44 max-h-44 overflow-hidden">
                      <SanityImage node={{ image: post.mainImage }} imgClasses="rounded-none" />
                    </div>
                  ) : null}
                  <div className="flex-1 bg-white p-6 -mt-2">
                    <Link href={`/posts/${post.slug}`}>
                      <a className="block">
                        <p className="text-lg font-semibold text-gray-900">{post.title}</p>
                        <p className="mt-2 text-sm line-clamp-3 sm:text-base text-gray-500">
                          {post.excerpt}
                        </p>
                      </a>
                    </Link>
                  </div>
                </article>
              </Transition>
            ))
          ) : (
            <div>
              <p className="text-center text-xl text-gray-600">No posts found!</p>
            </div>
          )}
        </div>
        {posts.length > max ? (
          <div className="mt-6 flex justify-center">
            <button className="btn-secondary" onClick={showMore}>
              Show More
            </button>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default BlogSection
