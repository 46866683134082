import Link from "next/link"

export default function Footer() {
  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <nav className="flex justify-center items-center space-x-6 mt-3 md:mt-0 md:order-1">
          {/* <Link href="/contact">
            <a className="text-gray-500 hover:text-gray-900">Contact</a>
          </Link> */}
          <Link href="/faq">
            <a className="text-gray-500 hover:text-gray-900">FAQ</a>
          </Link>
          <Link href="/privacy">
            <a className="text-gray-500 hover:text-gray-900">Privacy Policy</a>
          </Link>
          <Link href="/terms">
            <a className="text-gray-500 hover:text-gray-900">Terms of Service</a>
          </Link>
        </nav>
        <div className="mt-8 md:mt-0">
          <p className="text-center text-base text-gray-500">
            &copy; {new Date().getFullYear()} Skillfyre, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
