import SanityImage from "../../components/SanityImage"
import Link from "next/link"

const serializers = {
  types: {
    img: SanityImage,
    button: ({ node }) => {
      return (
        <>
          {node.internal ? (
            <Link href={node.url}>
              <a
                className="btn px-5 py-3"
                style={{
                  color: "white",
                  textDecoration: "none",
                }}>
                {node.text}
              </a>
            </Link>
          ) : (
            <a
              href={node.url}
              className="btn px-5 py-3"
              style={{
                color: "white",
                textDecoration: "none",
              }}>
              {node.text}
            </a>
          )}
        </>
      )
    },
  },
}

export default serializers
