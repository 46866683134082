import Image from "next/image"
import { urlForImage } from "../../common/lib/cms/cms"
import EmailSignupForm from "./EmailSignupForm"

const Hero = ({ content }) => {
  const index = content.headline.indexOf(content.highlight)
  const beforeHighlight = content.headline.substring(0, index)
  const afterHighlight = content.headline.substring(
    index + content.highlight.length,
    content.headline.length,
  )

  return (
    <div className="max-w-7xl mx-auto relative overflow-hidden px-4 sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-32">
      <div className="hidden lg:block lg:absolute lg:inset-0" aria-hidden="true">
        <svg
          className="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8"
          width={640}
          height={784}
          fill="none"
          viewBox="0 0 640 784">
          <defs>
            <pattern
              id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047"
              x={118}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse">
              <circle cx={6} cy={4} r={2} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect
            x={118}
            width={404}
            height={784}
            fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)"
          />
        </svg>
      </div>
      <main className="relative mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h1>
              <span className="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
                Coming March 2023
              </span>
              <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                {!!content.highlight ? (
                  <>
                    <span>{beforeHighlight}</span>{" "}
                    <span className="text-primary-600">{content.highlight}</span>
                    <span>{afterHighlight}</span>
                  </>
                ) : (
                  <span>{content.headline}</span>
                )}
              </span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              {content.body}
            </p>
            <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScRrMnrwv2h3aPrfYRZeTdG05TqfHt_xywpxZMpdsQdnWwV-Q/viewform?usp=sf_link"
                className="btn w-full py-3">
                Get an Invite
              </a>
              {/* <EmailSignupForm /> */}
            </div>
          </div>
          <div className="mt-14 relative h-96 sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <svg
              className="absolute top-2 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
              width={640}
              height={784}
              fill="none"
              viewBox="0 0 640 784"
              aria-hidden="true">
              <defs>
                <pattern
                  id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
                  x={118}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse">
                  <circle cx={6} cy={4} r={2} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect y={72} width={640} height={640} className="text-gray-50" fill="currentColor" />
              <rect
                x={118}
                width={404}
                height={784}
                fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"
              />
            </svg>
            <Image
              src={urlForImage(content.image.image).url()!}
              alt={content.image.alt}
              layout="fill"
              objectFit="cover"
              className="rounded-md"
            />
          </div>
        </div>
      </main>
    </div>
  )
}

export default Hero
