import { useState } from "react"
import { CheckCircleIcon } from "@heroicons/react/solid"
import { Switch } from "@headlessui/react"
import classNames from "../../common/lib/classnames"

export default function PricingSection({ content }) {
  const [isAnnual, setIsAnnual] = useState(false)

  return (
    <div className="bg-gray-50">
      <div className="max-w-6xl mx-auto py-16 px-4 sm:py-20 lg:py-24">
        <div className="relative">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
              <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                  {content.headline}
                </h3>
                <p className="mt-3 text-base text-gray-500 max-w-md">{content.body}</p>
                <div className="mt-8">
                  <div className="flex items-center">
                    <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-bold uppercase text-primary-600">
                      What's included
                    </h4>
                    <div className="flex-1 border-t-2 border-gray-200" />
                  </div>
                  <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                    {content.features &&
                      content.features.map((feature) => (
                        <li key={feature} className="flex items-start lg:col-span-1">
                          <div className="flex-shrink-0">
                            <CheckCircleIcon
                              className="h-5 w-5 text-green-500"
                              aria-hidden="true"
                            />
                          </div>
                          <p className="ml-3 text-sm text-gray-700">{feature}</p>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="py-8 px-6 text-center bg-gray-100 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                <div className="flex flex-row space-x-2 items-center justify-center">
                  <Switch
                    checked={isAnnual}
                    onChange={setIsAnnual}
                    className={classNames(
                      isAnnual ? "bg-primary-600" : "bg-gray-200",
                      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500",
                    )}>
                    <span className="sr-only">Select annual plan</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        isAnnual ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
                      )}
                    />
                  </Switch>
                  <div>
                    Annual <span className="text-gray-500">(Save 10%)</span>
                  </div>
                </div>
                <div className="mt-6 mx-auto w-40 flex items-end justify-center text-5xl font-extrabold text-gray-900">
                  <span>${isAnnual ? 150 : 14.99}</span>
                  <span className="ml-1 text-xl font-medium text-gray-500">
                    /{isAnnual ? "year" : "month"}
                  </span>
                </div>
                <div className="mt-6">
                  <div className="rounded-md shadow">
                    <a
                      href="#"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900">
                      Get started
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
