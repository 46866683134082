import React from "react"
import Head from "next/head"
import { getClient } from "../modules/common/lib/cms/cms.server"
import { indexQuery } from "../modules/common/lib/cms/queries"
import { usePreviewSubscription } from "../modules/common/lib/cms/cms"
import Hero from "../modules/marketing/components/Hero"
import ContentBlock from "../modules/common/components/ContentBlock"
import BlogSection from "../modules/marketing/components/BlogSection"
import FAQ from "../modules/marketing/components/FAQ"
import Testimonials from "../modules/marketing/components/Testimonials"
import Footer from "../modules/marketing/components/Footer"
import PricingSection from "../modules/marketing/components/PricingSection"
import CTA from "../modules/marketing/components/CTA"

export default function Home({ data = {}, preview }: { data: any; preview: boolean }) {
  const { data: previewData } = usePreviewSubscription(indexQuery, {
    initialData: data,
    enabled: preview,
  })

  const content = previewData?.index?.content || data.index.content
  const posts = previewData?.posts || data.posts

  const renderContent = (content, i) => {
    switch (content._type) {
      case "hero":
        return <Hero key={i} content={content} />
      case "contentSection":
        return <ContentBlock key={i} content={content} />
      case "articles":
        return <BlogSection key={i} content={content} posts={posts} />
      case "FAQ":
        return undefined
      // return <FAQ key={i} faqs={content.faqs} headline={content.headline} />
      case "testimonials":
        return (
          <Testimonials
            key={i}
            headline={content.headline}
            subheading={content.subheading}
            testimonials={content.testimonials}
          />
        )
      case "pricing":
        return <PricingSection key={i} content={content} />
      case "cta":
        return <CTA key={i} content={content} />
      default:
        throw new Error(`Unknown content type: ${content._type}`)
    }
  }

  return (
    <>
      <Head>
        <title>Skillfyre</title>
        <meta
          name="description"
          content="Design your strategic career path, based on real-world inputs using innovative
                  experiential courses, supported by a community of engaged learners"
        />
      </Head>
      <main>{content.map((c, i) => renderContent(c, i))}</main>
      <Footer />
    </>
  )
}

export async function getStaticProps({ preview = false }) {
  const data = await getClient(preview).fetch(indexQuery)
  return {
    props: { data, preview },
    revalidate: 60 * 2,
  }
}
