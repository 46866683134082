// Modules and units
export const unitIndexQuery = `
  *[_type == "unit"] | order(order desc, _updatedAt desc) {
    _id,
    title,
    slug,
    "count": coalesce(count(modules), 0)
  }
`

export const unitPathsQuery = `
*[_type == "unit" && defined(slug.current)][].slug.current
`

export const unitQuery = `
*[_type == "unit" && slug.current == $unit][0] {
  _id,
  title,
  "slug": slug.current,
  modules[] {
    _key,
    title,
    "slug": slug.current
  }
}
`

export const modulePathsQuery = `
*[_type == "unit"]{
  "slug": slug.current,
  "modules": coalesce(modules[].slug.current[], [])
}
`

export const moduleQuery = `
{
  "content": *[_type == 'unit' && slug.current == $unit][0] {
    _id,
    title,
    "module": modules[slug.current match $module][0],
  }, 
  "units": *[_type == 'unit']  | order(number asc)   {
    _id,
    title,
    "slug": slug.current,
    modules[]{
      _key,
      title,
      "slug": slug.current
    }
  }
}
`

// Posts and home page
const postFields = `
  _id,
  title,
  excerpt,
  mainImage {
    ...
  },
  "slug": slug.current,
`

export const indexQuery = `
{
	"index": *[_type == "home"][0],
  "posts": *[_type == "post"] | order(publishedAt desc, _updatedAt desc) {
    ${postFields}
  }
}`

export const postQuery = `
{
  "post": *[_type == "post" && slug.current == $slug] | order(_updatedAt desc) | [0] {
    content,
    ${postFields}
  }
}`

export const blogQuery = `
  *[_type == "post"] | order(publishedAt desc, _updatedAt desc) {
    ${postFields}
  }
`

export const postSlugsQuery = `
*[_type == "post" && defined(slug.current)][].slug.current
`

export const postBySlugQuery = `
*[_type == "post" && slug.current == $slug][0] {
  ${postFields}
}
`
