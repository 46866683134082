import Link from "next/link"

export default function CTA({ content }) {
  return (
    <div className="bg-primary-700">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">{content.headline}</h2>
        <p className="mt-4 text-lg leading-6 text-primary-200">{content.subheading}</p>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScRrMnrwv2h3aPrfYRZeTdG05TqfHt_xywpxZMpdsQdnWwV-Q/viewform?usp=sf_link"
          className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-md font-bold tracking-wide rounded-md text-primary-600 bg-white hover:bg-primary-50 sm:w-auto">
          {content.button}
        </a>
      </div>
    </div>
  )
}
