import BlockContent from "@sanity/block-content-to-react"
import serializers from "../lib/cms/serializers"
import SanityImage from "./SanityImage"

const ContentBlock = ({ content }) => {
  const renderBlock = (content, i) => {
    switch (content.layout) {
      case "left":
        return (
          <div key={i} className="px-4 py-8  md:grid md:grid-cols-2 md:gap-8 lg:gap-12">
            <div>
              <h2 className="text-base text-primary-600 font-semibold tracking-wide uppercase">
                {content.title}
              </h2>
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {content.headline}
              </h3>
              <div className="content prose py-4">
                <BlockContent
                  projectId={process.env.NEXT_PUBLIC_SANITY_PROJECT_ID}
                  dataset={process.env.NEXT_PUBLIC_SANITY_DATASET}
                  blocks={content.body}
                  serializers={serializers}
                />
              </div>
            </div>
            {content.image && (
              <div>
                <SanityImage node={content.image} />
              </div>
            )}
          </div>
        )
      case "right":
        return (
          <div key={i} className="px-4 py-8 grid md:grid-cols-2 md:gap-8 lg:gap-12">
            {content.image && (
              <div className="row-start-2 md:row-start-auto">
                <SanityImage node={content.image} />
              </div>
            )}
            <div className="row-start-1 md:col-start-2 mt-6 md:mt-0">
              <h2 className="text-base text-primary-600 font-semibold tracking-wide uppercase">
                {content.title}
              </h2>
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {content.headline}
              </h3>
              <div className="content prose py-4">
                <BlockContent
                  projectId={process.env.NEXT_PUBLIC_SANITY_PROJECT_ID}
                  dataset={process.env.NEXT_PUBLIC_SANITY_DATASET}
                  blocks={content.body}
                  serializers={serializers}
                />
              </div>
            </div>
          </div>
        )
      case "center":
        return (
          <div key={i} className="px-4 py-8">
            <div>
              <h2 className="md:text-center text-base text-primary-600 font-semibold tracking-wide uppercase">
                {content.title}
              </h2>
              <h3 className="md:text-center mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {content.headline}
              </h3>

              <div className="md:mx-auto content prose py-4">
                <BlockContent
                  projectId={process.env.NEXT_PUBLIC_SANITY_PROJECT_ID}
                  dataset={process.env.NEXT_PUBLIC_SANITY_DATASET}
                  blocks={content.body}
                  serializers={serializers}
                />
              </div>
              {content.image && (
                <div className="mt-4 lg:mt-6 max-w-3xl mx-auto">
                  <SanityImage node={content.image} />
                </div>
              )}
            </div>
          </div>
        )
      default:
        throw new Error(`Unknown content layout ${content.layout}`)
    }
  }
  // Custom id's - a bit hacky for now
  let id: string | undefined = undefined
  if (content?.content[0].headline === "About Skillfyre") {
    id = "about"
  }
  return (
    <div className="bg-white" id={id}>
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-20 lg:py-24">
        {content.content.map((block, i) => renderBlock(block, i))}
      </div>
    </div>
  )
}

export default ContentBlock
