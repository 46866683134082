import Image from "next/image"
import { urlForImage } from "../../common/lib/cms/cms"

export default function Testimonials({ headline, subheading, testimonials }) {
  return (
    <div className="bg-gray-900">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-20 lg:py-24">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="block text-sm font-semibold uppercase tracking-wide text-gray-200 sm:text-base lg:text-sm xl:text-base">
            {headline}
          </h2>
          <h3 className="text-center text-3xl font-extrabold text-white mt-3 sm:text-4xl">
            {subheading}
          </h3>
        </div>
        <div className="grid gap-4 mt-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-2">
          {testimonials.map((t, i) => (
            <div className="card mx-auto max-w-md flex flex-row space-x-3 px-4 py-3" key={i}>
              <div className="flex-grow">
                <div className="w-20 h-20 rounded-full overflow-hidden">
                  <Image
                    src={urlForImage(t.image.image).height(80).width(80).url()!}
                    alt={`Headshot of ${t.name}`}
                    height="80px"
                    width="80px"
                  />
                </div>
              </div>
              <div>
                <h4 className="text-md font-bold text-gray-900">{t.name}</h4>
                <h5 className="text-sm font-bold text-gray-500 tracking-wide">{t.title}</h5>
                <p className="text-gray-900 mt-2">{t.quote}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
