import Image from "next/image"
import { useNextSanityImage } from "next-sanity-image"
import { sanityClient } from "../lib/cms/cms.server"

const SanityImage = ({
  node,
  imgClasses,
  layout,
}: {
  node: any
  imgClasses?: string
  layout?: "fixed" | "intrinsic" | "responsive"
}) => {
  const { caption = "", alt = "", image } = node
  let imageProps = useNextSanityImage(sanityClient, image)

  return (
    <div>
      {/* @ts-ignore */}
      <Image {...imageProps} alt={alt} className={imgClasses || "rounded-md"} layout={layout} />
      {caption ? <span className="caption text-sm text-gray-700">{caption}</span> : null}
    </div>
  )
}

export default SanityImage
